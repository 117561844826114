import CSS from 'csstype';

export const color = {
    background: '#1a1a1a',
    numberBackground: '#a8a8a8',
    personBackground: '#333333',
    actionBackground: '#3c3c3c',
    objectBackground: '#343434',
    border: '#cccccc',
    person: '#ff6363',
    action: '#ffbd69',
    object: '#b277d4'
}

export const textStyle: CSS.Properties = {
    color: color.border,
    fontWeight: 100
};

export const textHeaderStyle: CSS.Properties = {
    color: color.numberBackground,
    fontWeight: 300
};

export const aspectRatio = .72;

import React from 'react';
import CSS from 'csstype';
import { textStyle, color } from './styles';
import { cards } from '../cardLibrary';
import CardDeck from './CardDeck';
import RandomNumber from './RandomNumber';
import RandomDate from './RandomDate';

export default class Home extends React.Component {
    render() {
        return (
            <div style={containerStyle}>
                <p style={{ ...textStyle, ...paragraphStyle }}>The Person Action Object (PAO) system is a method for memorizing abstract information like numbers, dates, or playing cards. The base of the system is the association of a person, action, and object with each digit.</p>
                <CardDeck cards={cards} />
                <p style={{ ...textStyle, marginTop: '2em', marginBottom: '2em', ...paragraphStyle }}>A number is mapped to a series of images by grouping it into 3-digit sets and forming an image for each set by taking the person corresponding to the first number in the set, the action from the second, and the object from the third. Because our brains are better suited to remember images, particularly those with an element of novelty, than numbers, the information is easier to remember when encoded this way. The set of images can be made even easier to remember by placing each image in the locus of a <a style={{ color: color.action }} href='https://en.wikipedia.org/wiki/Method_of_loci' target='_blank' rel='noopener noreferrer'>memory palace</a>. That is, imagine taking a walk through your home - walking in the door and seeing the first image, moving to the first room and seeing the second image, and so on. To recall the number, retake the imaginary walk, recall what's going on in each room, and map the image back to a number. Try it out!</p>
                <RandomNumber />
                <p style={{ ...textStyle, marginTop: '2em', marginBottom: '2em', ...paragraphStyle }}>It's a powerful system for memorizing large numbers quickly and committing them to long-term memory. However, this is just one potential use. All sorts of abstract information can be encoded in a number, and then encoded in images. Dates, for example, can be encoded in two images using this system.</p>
                <RandomDate />
                <p style={{ ...textStyle, marginTop: '2em', marginBottom: '2em', ...paragraphStyle }}>This example PAO system is the simplest version, using 1 digit per card. A more common and powerful version consists of 100 cards, with 2 digits per card, which allows you to memorize 6 digits, or one date, with a single image. Another common system is to have each PAO card correspond to a playing card, which allows you to memorize a deck of playing cards by memorizing 18 images. People even have PAO systems consisting of 1000 cards!</p>
                <p style={{ ...textStyle, ...paragraphStyle }}>It's a very useful and versatile system. <a style={{ color: color.action }} href='/app'>Check out the app</a> to build your own PAO deck, practice with it, and put it to use.</p>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3em', paddingLeft: '1em', paddingRight: '1em' }}>
                    <div style={{ borderStyle: 'solid', borderWidth: '.5', borderColor: color.personBackground }}>
                        <iframe title="Introduction video"  style={{ maxWidth: '100%' }} width="560" height="315" src="https://www.youtube.com/embed/vgkQLnSi8Ik" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        );
    }
}

const containerStyle: CSS.Properties = {
    marginTop: '2em'
};

const paragraphStyle: CSS.Properties = {
    paddingLeft: '2em',
    paddingRight: '2em'
};

import React from 'react';
import CSS from 'csstype';
import { IconContext } from 'react-icons';
import { IoMdRefresh } from 'react-icons/io';
import { cards as cardLibrary } from '../cardLibrary';
import { textStyle, color } from './styles';
import { CardProps } from './Card';
import CardDeck from './CardDeck';

interface P {
    displayString: string;
    randomNumber: number[];
    onRefresh: () => void;
}

export default class RandomValue extends React.Component<P> {

    render() {
        return (
            <div style={containerStyle}>
                <div style={numberContainerStyle}>
                    <h2 style={{ ...textStyle, marginRight: '1em' }}>{this.props.displayString}</h2>
                    <IconContext.Provider value={{ color: color.numberBackground, size: '2em' }}>
                        <div className='clickable' onClick={this.props.onRefresh}>
                            <IoMdRefresh />
                        </div>
                    </IconContext.Provider>
                </div>
                <CardDeck cards={this.getCards()} />
            </div>
        );
    }

    getCards() {
        const cards: CardProps[] = [];
        for (let i = 0; i < this.props.randomNumber.length; i += 3) {
            cards.push({
                symbol: `${this.props.randomNumber[i]}${this.props.randomNumber[i+1]}${this.props.randomNumber[i+2]}`,
                person: cardLibrary[this.props.randomNumber[i]].person,
                action: cardLibrary[this.props.randomNumber[i+1]].action,
                object: cardLibrary[this.props.randomNumber[i+2]].object
            });
        }
        return cards;
    }

}

const containerStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column'
}

const numberContainerStyle: CSS.Properties = {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '.1em',
    backgroundColor: '#292929'
}

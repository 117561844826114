import React from 'react';
import RandomValue from './RandomValue';

function getRandomNumber(numDigits: number) {
    return (new Array(numDigits)).fill(undefined).map(n => Math.floor(Math.random() * 10));
}

interface S {
    randomNumber: number[];
}

export default class RandomNumber extends React.Component<{}, S> {

    state: S = {
        randomNumber: getRandomNumber(15)
    };

    render() {
        return <RandomValue displayString={this.state.randomNumber.join('')}
                    randomNumber={this.state.randomNumber}
                    onRefresh={() => this.setState({ randomNumber: getRandomNumber(15) })} />
    }

}

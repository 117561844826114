import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import Card, { CardProps } from './Card';

export default class CardDeck extends React.Component<{ cards: CardProps[] }> {

    render() {
        return (
            <ScrollContainer horizontal={true} style={{ display: 'flex', paddingTop: '1.5em', paddingBottom: '1.5em', paddingLeft: '1.5em', paddingRight: '1.5em', backgroundColor: '#222222' }}>
                {this.props.cards.map(c => {
                    return (
                        <div style={{ marginRight: '1em' }}>
                            <Card {...c} />
                        </div>
                    );
                })}
                <p style={{ color: '#222222' }}>-</p>
            </ScrollContainer>
        )
    }
}

import React from 'react';
import CSS from 'csstype';
import { textStyle } from './styles';

export default class Contact extends React.Component {
    render() {
        return (
            <div style={containerStyle}>
                <p style={textStyle}>For all matters, contact Dayton Ellwanger at daytonellwanger@gmail.com.</p>
            </div>
        );
    }
}

const containerStyle: CSS.Properties = {
    paddingLeft: '2em',
    paddingRight: '2em',
    marginTop: '2em'
};

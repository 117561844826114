import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import TopBar from './TopBar';
import Home from './Home';
import App from './App';
import Contact from './Contact';

export default function Main() {
    return (
        <Router>
            <div>
                <TopBar />
                <div>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/app" component={App} />
                    <Route exact path="/contact" component={Contact} />
                </div>
            </div>
        </Router>
    );
}

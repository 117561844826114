import { CardProps } from './components/Card';

export const cards: CardProps[] = [
    {
        symbol: '0',
        person: 'Isaac Newton',
        action: 'Dropping',
        object: 'Apple'
    },
    {
        symbol: '1',
        person: 'Albert Einstein',
        action: 'Writing',
        object: 'Blackboard'
    },
    {
        symbol: '2',
        person: 'Steve Jobs',
        action: 'Talking Into',
        object: 'iPhone'
    },
    {
        symbol: '3',
        person: 'Charlie Chaplin',
        action: 'Swinging',
        object: 'Cane'
    },
    {
        symbol: '4',
        person: 'Arnold the Oak',
        action: 'Curling',
        object: 'Dumbbell'
    },
    {
        symbol: '5',
        person: 'Thor',
        action: 'Throwing',
        object: 'Hammer'
    },
    {
        symbol: '6',
        person: 'Darth Vader',
        action: 'Force Choking',
        object: 'Lightsaber'
    },
    {
        symbol: '7',
        person: 'Death',
        action: 'Sharpening',
        object: 'Scythe'
    },
    {
        symbol: '8',
        person: 'Gollum',
        action: 'Chasing',
        object: 'Fish'
    },
    {
        symbol: '9',
        person: 'Scooby Doo',
        action: 'Swallowing',
        object: 'Giant Sandwich'
    }
];

import React from 'react';
import CSS from 'csstype';
import { textStyle, textHeaderStyle, color } from './styles';
import ss1 from '../images/ss1.png';
import ss2 from '../images/ss2.png';
import ss3 from '../images/ss3.png';
import ss4 from '../images/ss4.png';
import appleAppStore from '../images/appleappstore.svg';

export default class App extends React.Component {
    render() {
        return (
            <div style={containerStyle}>
                <p style={{ ...textStyle, ...paragraphStyle, marginBottom: '1em' }}>The paomem app makes it easy to build, learn, practice, and use a PAO system. It’s available on the Apple App Store and Google Play.</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3em' }}>
                    <a href='https://apps.apple.com/us/app/id1525132229'><img style={{ maxWidth: '100%' }} width="200em" alt='Get it on Apple App Store' src={appleAppStore}/></a>
                    <a href='https://play.google.com/store/apps/details?id=ellwanger.dayton.paomem'><img style={{ maxWidth: '100%' }} width="250em" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                </div>
                <div style={{ ...sectionContainerStyle, marginBottom: '5em' }}>
                    <img src={ss1} alt="Build screenshot" style={{ maxWidth: '40%', width: '200px', height: 'auto', marginTop: '2em', marginRight: '3em' }} />
                    <div style={{ textAlign: 'right' }}>
                        <h2 style={{ ...textHeaderStyle, ...paragraphStyle, color: color.person }}>Build</h2>
                        <p style={{ ...textStyle, ...paragraphStyle }}>The first step is picking your person-action-object trios. The app comes with a default system; however, the ease of learning and using a PAO system depends on how vividly you can imagine the images it produces, so pick trios that are easy for you to visualize.</p>
                    </div>
                </div>
                <div style={{ ...sectionContainerStyle, marginBottom: '5em' }}>
                    <div style={{ textAlign: 'left' }}>
                        <h2 style={{ ...textHeaderStyle, ...paragraphStyle, color: color.action }}>Learn</h2>
                        <p style={{ ...textStyle, ...paragraphStyle }}>Before you can get started memorizing massive numbers, you have to memorize the pairing of the person-action-object trios with their digits. paomem provides flashcards to help with this. You can choose what pieces of the person-action-object trio to show, and whether to recall the digit for a trio or the trio for a digit. When memorizing numbers, you’ll need to be able to go from digit to image for memorization, and from image to digit for recall.</p>
                    </div>
                    <img src={ss2} alt="Learn screenshot" style={{ maxWidth: '40%', width: '200px', height: 'auto', marginTop: '2em', marginLeft: '3em' }}  />
                </div>
                <div style={{ ...sectionContainerStyle, marginBottom: '5em' }}>
                    <img src={ss3} alt="Practice screenshot" style={{ maxWidth: '40%', width: '200px', height: 'auto', marginTop: '2em', marginRight: '3em' }} />
                    <div style={{ textAlign: 'right' }}>
                        <h2 style={{ ...textHeaderStyle, ...paragraphStyle, color: color.object }}>Practice</h2>
                        <p style={{ ...textStyle, ...paragraphStyle }}>Once you can go from digit to image and back, you can start memorizing numbers. paomem will generate random numbers and their corresponding images*. After you’ve memorized the image, you can attempt to recall the number, choosing from a list of people, actions, or objects for each digit*.</p>
                        <br/>
                        <p style={{ ...textStyle, ...paragraphStyle }}>*you can turn off this feature once you’re comfortable performing the mapping yourself.</p>
                    </div>
                </div>
                <div style={{ ...sectionContainerStyle, marginBottom: '5em' }}>
                    <div style={{ textAlign: 'left' }}>
                        <h2 style={{ ...textHeaderStyle, ...paragraphStyle, color: '#48b048' }}>Use</h2>
                        <p style={{ ...textStyle, ...paragraphStyle }}>paomem can store numbers you’re trying to commit to memory and generate their corresponding images to help you memorize them.</p>
                    </div>
                    <img src={ss4} alt="Use screenshot" style={{ maxWidth: '40%', width: '200px', height: 'auto', marginTop: '2em', marginLeft: '3em' }}  />
                </div>
                <p style={{ ...textStyle, ...paragraphStyle }}>paomem supports 1 and 2 digit PAO systems, as well as PAO systems for memorizing playing cards.</p>
            </div>
        );
    }
}

const containerStyle: CSS.Properties = {
    paddingLeft: '2em',
    paddingRight: '2em',
    marginTop: '2em'
};

const sectionContainerStyle: CSS.Properties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
};

const paragraphStyle: CSS.Properties = {
    paddingLeft: '3em',
    paddingRight: '3em',
    padding: 0
};

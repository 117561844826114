import React from 'react';
import CSS from 'csstype';
import { NavLink } from 'react-router-dom';
import { color } from './styles';

const opacity = 'aa';

export default function TopBar() {
    return (
        <div style={containerStyle}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 style={{ ...headerStyle, marginRight: 15 }}>
                    <span style={{ color: color.person + opacity }}>P</span>
                    <span style={{ color: color.personBackground }}>erson</span>
                </h1>
                <h1 style={{ ...headerStyle, marginRight: 15 }}>
                    <span style={{ color: color.action + opacity }}>A</span>
                    <span style={{ color: color.actionBackground }}>ction</span>
                </h1>
                <h1 style={{ ...headerStyle }}>
                    <span style={{ color: color.object + opacity }}>O</span>
                    <span style={{ color: color.objectBackground }}>bject</span>
                </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
                <NavLink style={linkStyle} to="/">Home</NavLink>
                <p style={linkSeparatorStyle}>|</p>
                <NavLink style={linkStyle} to="/app">App</NavLink>
                <p style={linkSeparatorStyle}>|</p>
                <NavLink style={linkStyle} to="/contact">Contact</NavLink>
            </div>
        </div>
    );
}

const containerStyle: CSS.Properties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderColor: color.actionBackground,
    alignItems: 'flex-end',
    borderStyle: 'solid',
    borderWidth: '0px',
    borderBottomWidth: '1px',
    marginLeft: '1.5em',
    marginRight: '1.5em',
    paddingLeft: '1.5em',
    paddingRight: '1.5em',
    paddingBottom: '.5em'
};

const headerStyle: CSS.Properties = {
    fontWeight: 100,
    lineHeight: 0
};

const linkStyle: CSS.Properties = {
    color: color.numberBackground,
    textDecoration: 'none'
}

const linkSeparatorStyle: CSS.Properties = {
    color: color.actionBackground,
    margin: '1em',
    lineHeight: 0
}

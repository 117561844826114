import React from 'react';
import CSS from 'csstype';
import { color, aspectRatio } from './styles';

export interface CardProps {
    symbol: string;
    person: string;
    action: string;
    object: string;
}

export default class Card extends React.Component<CardProps> {

    render() {
        return (
            <div style={containerStyle}>
                <div style={symbolContainerStyle}>
                    <p style={symbolTextStyle}>{this.props.symbol}</p>
                </div>
                <div style={{ ...bodySectionContainerStyle, backgroundColor: color.personBackground, borderLeftColor: color.person }}>
                    <p style={bodyTextStyle}>{this.props.person}</p>
                </div>
                <div style={{ ...bodySectionContainerStyle, backgroundColor: color.actionBackground, borderLeftColor: color.action }}>
                    <p style={bodyTextStyle}>{this.props.action}</p>
                </div>
                <div style={{ ...bodySectionContainerStyle, display: 'flex', flexGrow: 1, backgroundColor: color.objectBackground, borderLeftColor: color.object }}>
                    <p style={bodyTextStyle}>{this.props.object}</p>
                </div>
            </div>
        );
    }

}

const containerStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    width: 13*aspectRatio + 'em',
    height: '13em',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: color.border
}

const symbolContainerStyle: CSS.Properties = {
    backgroundColor: color.numberBackground,
    padding: '.3em',
    paddingLeft: '.5em',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: color.border
}

const symbolTextStyle: CSS.Properties = {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    color: color.objectBackground,
    fontSize: '1.3em',
    fontWeight: 'bolder'
}

const bodySectionContainerStyle: CSS.Properties = {
    paddingTop: '.5em',
    paddingBottom: '.5em',
    paddingLeft: '.8em',
    paddingRight: '.8em',
    borderLeftWidth: '3px',
    borderLeftStyle: 'solid'
}

const bodyTextStyle: CSS.Properties = {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    color: color.border,
    fontSize: '1em'
}

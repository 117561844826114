import React from 'react';
import moment from 'moment';
import RandomValue from './RandomValue';

function getRandomDate() {
    const msInCentury = 100*365*24*60*60*1000
    const centuryAgo = (new Date()).getTime() - msInCentury;
    return new Date(centuryAgo + (Math.random() * msInCentury));
}

interface S {
    randomDate: Date;
}

export default class RandomDate extends React.Component<{}, S> {

    state: S = {
        randomDate: getRandomDate()
    };

    render() {
        const year = this.state.randomDate.getFullYear().toString().substr(2, 2);
        let month = (this.state.randomDate.getMonth() + 1).toString();
        if (month.length < 2) {
            month = '0' + month;
        }
        let day = this.state.randomDate.getDate().toString();
        if (day.length < 2) {
            day = '0' + day;
        }
        const randomNumber = [parseInt(year[0]), parseInt(year[1]), parseInt(month[0]), parseInt(month[1]), parseInt(day[0]), parseInt(day[1])];

        return <RandomValue displayString={moment(this.state.randomDate.toString()).format('MMMM Do, YYYY')}
                    randomNumber={randomNumber}
                    onRefresh={() => this.setState({ randomDate: getRandomDate() })} />;
    }

}
